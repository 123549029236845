import { useParams, useSearchParams } from "react-router-dom";
import "./chat.css";
import { useEffect, useRef, useState } from "react";
import { Loading } from "../common/loader";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import useWebSocket from "react-use-websocket";
import Markdown from 'react-markdown'

export const ChatWidget = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [chatStreaming, setChatStreaming] = useState(false);
  const [chat, setChat] = useState([{ role: 'assistant', content: `Hi, I'm Skyler, Your AI assistant. How can i help you today?` }])
  const [sampleQus, setSampleQus] = useState([])
  const [secretKey, setSecretKey] = useState("")
  const [queryString, setQueryString] = useState("")
  const [queryParams] = useSearchParams();
  const socketURL = `wss://api.skylars.io/ws/basic-chat/${secretKey}/`
  const chatContainerRef = useRef(null);

  const { sendMessage, lastMessage, readyState } = useWebSocket(
    `${socketURL}`
  );

  useEffect(() => {
    if (lastMessage) {
      setChatLoading(false)
      let messageResponse = JSON.parse(lastMessage.data);
      if (messageResponse.stream) {
        setChatStreaming(true)
        setChat(prevChat =>
          prevChat.map((msg, index) =>
            index === prevChat.length - 1 ? { ...msg, content: messageResponse.message } : msg
          )
        );
      } else {
        setChatStreaming(false)
      }
    }

  }, [lastMessage]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chat]);

  useEffect(() => {
    setSecretKey(queryParams.get("secretKey"))
    let tempSampQus = queryParams.get("sampleQus")
    let tempUserQus = queryParams.get("userQus")
    if (tempSampQus) {
      setSampleQus(JSON.parse(tempSampQus))
    }
    if(tempUserQus){
      if(readyState === 1){
        askQus(tempUserQus)
      }
    }

  }, [readyState])

  function askQus(qus) {
    setQueryString("");
    setChat(prevChat => [...prevChat, { role: 'user', content: qus }, { role: 'assistant', content: '' }])
    sendMessage(JSON.stringify({ question: qus }));
    setChatLoading(true)
  }

  return (
    <div>
      <div className="skylar-chat-modal">
        {isLoading && <Loading />}
        <div className="skyler-head">
          <img src="/images/skyler_logo.png" />
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16" onClick={() => hideFrame()}>
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg> */}
        </div>
        <div className="skylar-chatbody-outer">
          <div className="skylar-chatbox-body" ref={chatContainerRef}>
            {
              sampleQus?.length ?
                <>
                  {
                    sampleQus?.map((qus, i) => (
                      <div className="left-chat-box" key={`sample-${i}`}>
                        <p
                          style={{ cursor: "pointer" }}
                          onClick={() => askQus(qus)}
                        ><img src="/images/star.png" />
                          {qus}
                        </p>
                      </div>
                    ))
                  }
                </>
                :
                ""
            }

            {
              chat.map((c, i) => (
                <div className={c.role == 'user' ? 'right-chat-box' : 'ai-chat-box'} key={i}>
                 
                  {
                    chatLoading && !c.content ?
                      <div className="typing-loader"></div>
                      : ""
                  }
                  {
                    c.content ?
                        <>
                        {
                        c.role == 'user' 
                        ?
                        <p>{c.content}</p>
                         :
                         <div className="ai-message">
                          <img src="/images/skyler_icon.png" />
                           <Markdown>{c.content}</Markdown>
                         </div> 
                         } 
                        </>
                      : ""
                  }
                </div>
              ))
            }


          </div>
        </div>
        <div className="skylar-chat-footer">
          <Form.Control
            as={"textarea"}
            value={queryString}
            onChange={(e) => setQueryString(e.target.value)}
          />
          <Button size="sm" className="send-message" disabled={!queryString.trim() || chatStreaming} onClick={() => askQus(queryString)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send-fill" viewBox="0 0 16 16">
              <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z" />
            </svg>
          </Button>
        </div>
      </div>
    </div>
  )
}